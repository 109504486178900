import React from 'react'

import HeroSection from 'components/pages/shared/hero-section'
import useDimensions from 'js/utils/hooks/useDimensions'

import DektopPreview from './parts/desktop-preview'

function HeaderSection({ location, pageName, data }) {
  const { backgroundImage, previewMedia } = data

  const { isMobile } = useDimensions()

  return (
    <HeroSection
      location={location}
      pageName={pageName}
      data={data}
      backgroundImage={backgroundImage}
      downloadButtonProps={{ forceShowDownloadPageButton: isMobile }}
      limitBackgroundMobileHeight
    >
      {!!previewMedia?.images && <DektopPreview images={previewMedia.images} />}
    </HeroSection>
  )
}

export default HeaderSection
