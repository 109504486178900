import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import './desktop-preview.scss'

function DektopPreview({ images }) {
  const { image, gradient } = images

  if (!image) return null

  return (
    <div className="x__desktop-page__preview">
      {gradient && (
        <GatsbyImage
          className="x__desktop-page__preview__gradient"
          image={gradient}
          alt="Gradient blur"
          loading="lazy"
        />
      )}
      <div className="x__desktop-page__preview__content">
        <GatsbyImage image={image} alt="Desktop preview" loading="eager" />
      </div>
    </div>
  )
}

export default DektopPreview
