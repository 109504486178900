import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

import useDimensions from 'js/utils/hooks/useDimensions'

import Carousel from '../parts/carousel'

import './styles.scss'

const iconNameIndex = ['keys', 'trezor', 'support', 'sync', 'easy', 'users', 'buy']

function Item({ name, title, description, variant, iconSprite, className }) {
  const iconPosition = { col: iconNameIndex.indexOf(name) + 1, row: variant }

  return (
    <div className="x__wallet-page__features__item">
      <div
        className={classNames('x__icon', {
          [`c${iconPosition.col}`]: iconPosition.col,
          [`r${iconPosition.row}`]: iconPosition.row,
          [className]: className,
        })}
      >
        <GatsbyImage image={iconSprite} alt={`${title} icon`} loading="lazy" />
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  )
}

function FeaturesSection({ data, variant = 1 }) {
  const { h2, items, backgroundImage } = data

  const { isMobile } = useDimensions()

  const images = useStaticQuery(graphql`
    query {
      sprite: file(relativePath: { regex: "/^wallet\/img\/wallet-features-sprite\\.png$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 2688)
        }
      }
    }
  `)
  const iconSprite = getImage(images.sprite)

  if (!items.length) return null

  return (
    <section className="x__wallet-page__features">
      {!!backgroundImage && (
        <GatsbyImage
          className="x__wallet-page__features__background"
          image={backgroundImage}
          alt="background"
          loading="lazy"
        />
      )}
      <div className="x__wallet-page__features__content">
        {!!h2 && (
          <h2
            className="x__wallet-page__features__heading"
            dangerouslySetInnerHTML={{ __html: h2 }}
          />
        )}
        {isMobile ? (
          <div className="x__wallet-page__features__carousel">
            <Carousel
              pause={!isMobile}
              items={items}
              ItemComponent={({ item }) => (
                <Item variant={variant} iconSprite={iconSprite} {...item} />
              )}
            />
          </div>
        ) : (
          <div className="x__wallet-page__features__items">
            {items.map((item) => (
              <Item key={item.name} variant={variant} iconSprite={iconSprite} {...item} />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default FeaturesSection
