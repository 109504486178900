import { graphql } from 'gatsby'
import { getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

import { DownloadButton } from 'components/button/download-v2'
import SimpleHead from 'components/head/SimpleHead'
import PageWrapper from 'components/layout/page-wrapper'
import DiversifySection from 'components/pages/desktop-wallet/diversify-section'
import HeaderSection from 'components/pages/desktop-wallet/header-section'
import AssetsSection from 'components/pages/shared/assets-section'
import FeaturesSection from 'components/pages/shared/features-section'
import QuoteSection from 'components/pages/shared/quote-section'
import ShowcaseSection from 'components/pages/shared/showcase-section'
import pageData from 'data/pages/Contents/desktop.json'
import useDimensions from 'js/utils/hooks/useDimensions'

function DesktopPage({ location, data: images = {} }) {
  // get page data.
  const { name: capitalizedPageName, path: pageName, content } = pageData
  const { header, quote, assets, swap, diversify, sendReceive, features } = content

  const { isMobile } = useDimensions()

  const backgroundImage = withArtDirection(getImage(images.bgL), [
    { media: '(max-width: 768px)', image: getImage(images.bgS) },
  ])

  const sectionImages = {
    header: {
      images: {
        image: withArtDirection(getImage(images?.previewL), [
          { media: '(max-width: 768px)', image: getImage(images?.previewS) },
        ]),
        gradient: getImage(images?.previewGradient),
      },
    },
    assets: { bg: getImage(images?.bgAssets) },
    swap: { img: getImage(images?.swapL), bg: getImage(images?.bgSwap) },
    diversify: {
      img: withArtDirection(getImage(images?.diversifyL), [
        { media: '(max-width: 768px)', image: getImage(images?.diversifyS) },
      ]),
    },
    sendReceive: { img: getImage(images?.sendReceiveL), bg: getImage(images?.bgSendReceive) },
    features: { bg: getImage(images?.bgFeatures) },
  }

  const downloadButtonPillProps = {
    location,
    trackerPage: `${capitalizedPageName}Page`,
    androidLinkProps: {
      utmSource: 'exodus-website',
      utmCampaign: `${pageName}-wallet`,
      utmContent: `${pageName}-wallet`,
    },
  }
  const downloadButtonProps = {
    forceShowDownloadPageButton: false,
    size: 'small',
    style: { backgroundImage: `linear-gradient(270deg, #420BE6, #A16BB3)` },
  }

  return (
    <PageWrapper className="x__wallet-page" headerProps={{ location, trackerPage: pageName }}>
      {!!header && (
        <HeaderSection
          location={location}
          pageName={pageName}
          data={{ ...header, backgroundImage, previewMedia: sectionImages.header }}
        />
      )}
      {!!quote && <QuoteSection data={quote} />}
      {!!assets && (
        <AssetsSection id="assets" data={{ ...assets, backgroundImage: sectionImages.assets.bg }} />
      )}
      {!!swap && (
        <ShowcaseSection
          data={{
            ...swap,
            previewMedia: { images: sectionImages.swap.img },
            backgroundImage: sectionImages.swap.bg,
          }}
        >
          {!isMobile && <DownloadButton {...downloadButtonPillProps} {...downloadButtonProps} />}
        </ShowcaseSection>
      )}
      {!!diversify && (
        <DiversifySection data={{ ...diversify, previewImage: sectionImages.diversify.img }} />
      )}
      {!!sendReceive && (
        <ShowcaseSection
          data={{
            ...sendReceive,
            previewMedia: { images: sectionImages.sendReceive.img },
            backgroundImage: sectionImages.sendReceive.bg,
          }}
        >
          {!isMobile && <DownloadButton {...downloadButtonPillProps} {...downloadButtonProps} />}
        </ShowcaseSection>
      )}
      {!!features && (
        <FeaturesSection
          data={{ ...features, backgroundImage: sectionImages.features.bg }}
          variant={1}
        />
      )}
    </PageWrapper>
  )
}

export default DesktopPage

// <head> component:
export function Head() {
  const { path, title, description, keywords } = pageData

  return <SimpleHead path={path} title={title} description={description} keywords={keywords} />
}

export const query = graphql`
  query {
    bgS: file(relativePath: { regex: "/^desktop\/img\/hero-bg-s\\.jpg$/" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, width: 768, placeholder: BLURRED)
        }
      }
    bgL: file(relativePath: { regex: "/^desktop\/img\/hero-bg-l\\.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 3200, placeholder: BLURRED)
      }
    }
    previewS: file(relativePath: { regex: "/^desktop\/img\/header-preview-s\\.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 490, placeholder: BLURRED)
      }
    }
    previewL: file(relativePath: { regex: "/^desktop\/img\/header-preview-l\\.jpg$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2224, placeholder: BLURRED)
      }
    }
    previewGradient: file(relativePath: { regex: "/^desktop\/img\/header-preview-gradient\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 1521, placeholder: BLURRED)
      }
    }
    bgAssets: file(relativePath: { regex: "/^desktop\/img\/assets-section-glow\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED)
      }
    }
    swapL: file(relativePath: { regex: "/^desktop\/img\/image-swap-l\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2160, placeholder: BLURRED)
      }
    }
    bgSwap: file(relativePath: { regex: "/^desktop\/img\/showcase-glow-0\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED)
      }
    }
    sendReceiveL: file(relativePath: { regex: "/^desktop\/img\/image-send-receive-l\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2160, placeholder: BLURRED)
      }
    }
    bgSendReceive: file(relativePath: { regex: "/^desktop\/img\/showcase-glow-1\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED)
      }
    }
    diversifyL: file(relativePath: { regex: "/^desktop\/img\/image-diversify-l\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 2499, placeholder: BLURRED)
      }
    }
    diversifyS: file(relativePath: { regex: "/^desktop\/img\/image-diversify-s\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 960, placeholder: BLURRED)
      }
    }
    bgFeatures: file(relativePath: { regex: "/^desktop\/img\/features-section-glow\\.png$/" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 1600, placeholder: BLURRED)
      }
    }
  }
`
