import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import Slider from 'react-slick'

import './carousel.scss'

function Indicators({ items, activeIndex, onClick }) {
  return (
    <div className="exodus__carousel__indicators">
      {items.map((item, index) => (
        <button
          key={index}
          className={classNames('exodus__carousel__indicators__dot', {
            active: activeIndex === index,
          })}
          onClick={() => onClick(index)}
          aria-label={`Go to slide ${index + 1}`}
          type="button"
        />
      ))}
    </div>
  )
}

function Carousel({
  items = [],
  itemWrapperClassName,
  ItemComponent,
  pause = false,
  settings = {},
}) {
  const carouselRef = useRef(null)

  const [activeIndex, setActiveIndex] = useState(0)
  const [targetIndex, setTargetIndex] = useState(null)

  // Handle item change.
  const handleGoToSlide = (i) => {
    carouselRef.current.slickGoTo(i)
  }

  const carouselSettings = {
    ref: carouselRef,
    dots: true,
    arrows: false,
    infinite: true,
    lazyLoad: 'ondemand',
    autoplay: !pause,
    slickPause: pause,
    speed: 350,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    afterChange: (current) => {
      setActiveIndex(current)
      setTargetIndex(null)
    },
    beforeChange: (current, next) => {
      setTargetIndex(next)
    },
    ...settings, // Custom settings.
  }

  if (!items?.length) return null

  return (
    <div className="exodus__carousel">
      <Slider
        {...carouselSettings}
        appendDots={(dots) => (
          <Indicators items={dots} activeIndex={activeIndex} onClick={handleGoToSlide} />
        )}
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={classNames(itemWrapperClassName, {
              // There is an issue in react-slick with the custom transition between the last and first slides.
              // When we switch between the first and last slides `transform: scale(1)` is applied with a delay
              // and without animation. Adding custom class to the slide where we are navigating and adding
              // scaling to it resolves the issue.
              'slick-target': targetIndex === index,
            })}
          >
            <ItemComponent item={item} />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carousel
