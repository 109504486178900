import classNames from 'classnames'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import './styles.scss'

function QuoteSection({ data, children }) {
  const exodusSymbol = '../../../../../static/img/exodus-symbol-white.svg'

  const { description, background, chromes } = data

  return (
    <section className="x__quote-section">
      {!!background && (
        <GatsbyImage
          alt="Border image"
          className="x__quote-section__border"
          image={background}
          loading="lazy"
        />
      )}
      {!!chromes && (
        <GatsbyImage
          alt="Background chromes"
          className="x__quote-section__background"
          image={chromes}
          loading="lazy"
        />
      )}
      <div
        className={classNames('x__quote-section__content', {
          'x__quote-section__content--with-chromes': !!chromes,
        })}
      >
        <StaticImage
          alt="Exodus Symbol"
          className="x__quote-section__logo"
          src={exodusSymbol}
          height={20}
          width={20}
        />
        {!!description && <p dangerouslySetInnerHTML={{ __html: data.description }} />}
        {!!children && <div>{children}</div>}
      </div>
    </section>
  )
}

export default QuoteSection
