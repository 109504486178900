import classNames from 'classnames'
import React from 'react'

import './description.scss'

function Description({ text, position }) {
  return (
    <div className={classNames('exodus__showcase__description', { [position]: position })}>
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  )
}

export default Description
