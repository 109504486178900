import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'

import Button from 'components/button'

import { SectionHeading } from 'src/components/pages/shared'
import Description from './parts/description'
import Carousel from '../parts/carousel'

import './styles.scss'

function PreviewMedia({ images, children, onClick }) {
  const Component = onClick ? 'button' : 'div'

  return useMemo(() => {
    if (!images) return null

    if (Array.isArray(images)) {
      return (
        <Component className="exodus__showcase__carousel" onClick={onClick}>
          <Carousel
            items={images}
            itemWrapperClassName="exodus__showcase__item"
            ItemComponent={({ item, key }) => (
              <GatsbyImage
                image={item}
                alt={`Preview image ${(key % images.length) + 1}`}
                loading="lazy"
              />
            )}
          />
        </Component>
      )
    } else {
      return (
        <Component className="exodus__showcase__image" onClick={onClick}>
          <GatsbyImage image={images} alt="Preview image" loading="lazy" />
          {children}
        </Component>
      )
    }
  }, [images, children, onClick])
}

function ShowcaseSection({ id, data, descriptionPosition = 'left', children, onClick }) {
  const { h2, h3, description, cta, previewMedia, backgroundImage } = data

  return (
    <>
      <section
        id={id}
        className={classNames('exodus__showcase', {
          'exodus__showcase--no-background-image': !backgroundImage,
        })}
      >
        {!!backgroundImage && (
          <GatsbyImage
            className="exodus__showcase__background"
            image={backgroundImage}
            alt="background"
            loading="lazy"
          />
        )}

        <div className="exodus__showcase__content">
          <SectionHeading h2={h2} h3={h3} />
          {!!previewMedia?.images && (
            <PreviewMedia images={previewMedia.images} onClick={onClick}>
              {!!description && <Description text={description} position={descriptionPosition} />}
            </PreviewMedia>
          )}
          {!!cta && (
            <div className="exodus__showcase__actions">
              <Button to={cta.path} copy={cta.copy} size="largest" />
            </div>
          )}
          {children}
        </div>
      </section>
    </>
  )
}

export default ShowcaseSection
