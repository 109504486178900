import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import './diversify-section.scss'

function DiversifySection({ data }) {
  const { h3, previewImage } = data

  return (
    <section className="x__wallet-page__diversify">
      <div className="x__wallet-page__diversify__content">
        {!!h3 && (
          <h3
            className="x__wallet-page__diversify__heading"
            dangerouslySetInnerHTML={{ __html: h3 }}
          />
        )}

        {!!previewImage && (
          <div className="x__wallet-page__diversify__image">
            <GatsbyImage image={previewImage} alt="Diversify image" loading="lazy" />
          </div>
        )}
      </div>
    </section>
  )
}

export default DiversifySection
